import React from "react";
import data from "../../data/data.json";
import MediumIcon from "../../assets/icons/Medium Icon.svg";
import TwitterIcon from "../../assets/icons/Twitter Icon.svg";
import TelegramIcon from "../../assets/icons/Telegram Icon.svg";
import { Row, Col, Typography } from "antd";
import classes from "./BannerCard.module.css";
import BrandLogo from "../../assets/icons/icon defi.png";
import SaleStep from "../SaleSteps/Steps.js";

const { Title, Paragraph } = Typography;

const BannerCard = (props) => {
  return (
    <Row className={classes.container}>
      <Col item lg={14} md={24} className={classes.about}>
        <img
          src={BrandLogo}
          height="50px"
          className={classes.logo}
          alt="a logo for staking app"
        />
        <Title level={1} className={classes.title}>
          {data.title}
        </Title>
        <Paragraph className={classes.token}>${data.token}</Paragraph>
        <Paragraph className={classes.description}>
          {data.description}
        </Paragraph>
        <div className={classes.iconTrey}>
          <a href="https://blog.defiyield.app/">
            <img src={MediumIcon} alt="icon" className={classes.socialIcon} />
          </a>
          <a href="https://twitter.com/defiyield_app">
            <img src={TwitterIcon} alt="icon" className={classes.socialIcon} />
          </a>
          <a href="https://t.me/defiyield_appt">
            <img src={TelegramIcon} alt="icon" className={classes.socialIcon} />
          </a>
        </div>
      </Col>
      <Col
        lg={8}
        md={24}
        className={classes.staking}
        style={{ position: "relative" }}
      >
        <SaleStep />
      </Col>
    </Row>
  );
};

export default BannerCard;
