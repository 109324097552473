import React from "react";
import classes from "./StakingCard.module.css";
import { Typography, Button } from "antd";
import ProgressBar from "../ProgressBar/ProgressBar";
import InfoTable from "./infoTable";

import * as Campaign from "../../blockchain/contracts/campaign";
import * as Token from "../../blockchain/contracts/token";
import { address } from "../../blockchain/address";


import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const FCFSSale = (props) => {
  // eslint-disable-next-line 
  const [infoData, setInfoData] = React.useState([
    {
      title: "DeFiYield TOKEN BALANCE",
      value: props.data.idoTokenBalance,
    },
    {
      title: "FCFS TOKEN LEFT",
      value: props.data.fcfsTokenLeft,
    },
    {
      title: "REMAINING MAX BUY",
      value: props.data.fcfsMaxBuy,
    },
  ]);
  const [usdc, setUSDC] = React.useState("");
  const [coin, setCoin] = React.useState("");

  async function buyFCFSToken() {
    try{
      props.loading(true, 2);
      const status = await Token.approvePayToken(props.data.account, address.campaign, Number(usdc)*1000000);
      if(!status) throw new Error("Approval failed");
      await Campaign.buyFCFS(Number(usdc)*1000000, props.data.account);
      return true;
    }
    catch(error){
      console.error(error);
    }
    finally{
      props.loading(false, 2);
    }
  }

  React.useEffect(() => {
    Campaign.calculateTokenAmount(Number(usdc)*1000000)
    .then((token) => {
      setCoin(token)
    });
  }, [usdc]);

  return (
    <>
      <div className={classes.root}>
        <InfoTable data={infoData} title="FCFS Sale" />
        <div className={classes.inputContainerBox}>
          <input
            className={classes.inputBox}
            placeholder="0.00"
            type="number"
            onChange={(e) => setUSDC(e.target.value)}
          />
          <Typography variant="h4" className={classes.inputLabel}>
            USDC
          </Typography>
        </div>

        <Typography variant="h4" className={classes.subText}>
          Enter USDC amount
        </Typography>
        <div className={classes.inputContainerBox}>
          <input
            className={classes.inputBox}
            placeholder="0.00"
            type="text"
            disabled={true}
            value={coin}
          />
          <Typography variant="h4" className={classes.inputLabel}>
            DEFI
          </Typography>
        </div>
        <Typography variant="h4" className={classes.subText}>
          If I pay {usdc} USDC, how many tokens do I get?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={buyFCFSToken}
          // eslint-disable-next-line
          disabled={!props.data.hasRegistered}
          fullWidth
        >
        {props.data.hasRegistered? "BUY": "INELIGIBLE"}
        </Button>
      </div>
      <div style={{ paddingTop: "60px" }}></div>
      <ProgressBar progress={props.data.progress} />
    </>
  );
}

export default connect(null, { loading })(FCFSSale);