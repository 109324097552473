import React from "react";
import classes from "./StakingCard.module.css";
import { Row, Col, Typography } from "antd";

export default function InfoTable({ data, title }) {
  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.table}>
        <div>
          {data?.map((item, index) => {
            return (
              <Row
                key={index}
                justify="space-between"
                align="middle"
                className={classes.dataRow}
              >
                <Col>
                  <Typography variant="body1" className={classes.key}>
                    {item.title}
                  </Typography>
                </Col>
                <Col>
                  <Typography
                    variant="body1"
                    align="right"
                    className={classes.value}
                  >
                    {item.value}
                  </Typography>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </div>
  );
}
