import "./App.less";
import { RouteHandler } from "./router";
import { GlobalLoader } from "./components";
import configureStore from "./redux";
import { Provider } from "react-redux";
// CREATING REACT STORE INSTANCE
const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <GlobalLoader />
      <RouteHandler />
    </Provider>
  );
}

export default App;
