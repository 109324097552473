const chain = { // Fantom chain
    chainId: '250',
    rpcUrls: ['https://rpc.ftm.tools',],
    blockExplorerUrls: ['https://ftmscan.com',],
    iconUrls: ['https://cryptologos.cc/logos/fantom-ftm-logo.png',],
    chainName: 'Fantom Opera',
    nativeCurrency: {
        name: 'Fantom',
        symbol: 'FTM',
        decimals: 18,
    },
}
export default chain;