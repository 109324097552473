import web3 from "../web3";
import { StakerInterface } from "../interfaces";
import { address } from '../address';

const contract = new web3.eth.Contract(StakerInterface, address.staker);

/**
 * 
 * @param {account of the buyer} spender aka staker
 * @param {amount of tokens to be bought in ether} amount 
 * @returns true if successfully executed
 */
export const stakeTokens = async (spender, amount) => {
    await contract.methods
        .stake(web3.utils.toWei(amount, 'ether'))
        .send({ from: spender });
    return true;
}

export const unStakeTokens = async (spender, amount) => {
    await contract.methods
        .unstake(web3.utils.toWei(amount, 'ether'))
        .send({ from: spender });
    return true;
}

export const unlockTime = async (account) => {
    return await contract.methods
        .unlockTime(account)
        .call();
}

export const stakedBalance = async (account) => {
    return web3.utils.fromWei(await contract.methods
        .stakedBalance(account)
        .call(), 'ether');
}