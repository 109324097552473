import web3 from "../web3";
import { TokenInterface, TransactionTokenInterface } from '../interfaces';
import { address } from '../address';

const contract = new web3.eth.Contract(TokenInterface, address.token);
const payContract = new web3.eth.Contract(TransactionTokenInterface, address.trans);

/**
 * 
 * @param {Who holds the token} owner 
 * @param {Who will spend the token ideally a contract} spender 
 * @param {Amount he will get allowance for} amount 
 * @returns true if successfully executed
 */
export const approve = async (owner, spender, amount) => {
  const approval = await contract.methods
      .approve(spender, web3.utils.toWei(amount, 'ether'))
      .send({ from: owner });
  return approval.status;
}


export const approvePayToken = async (owner, spender, amount) => {
  const approval = await payContract.methods
      .approve(spender, amount)
      .send({ from: owner });
  return approval.status;
}

export const balanceOf = async (account) => {
  return web3.utils.fromWei(await contract.methods
      .balanceOf(account)
      .call(), 'ether');
}

/**
 * 
 * @param {window.ethereum only works with client side} provider 
 * @returns void
 */
export const addTokenToMetamask = async () => {
    window.ethereum.sendAsync({
        method: 'metamask_watchAsset',
        params: {
          "type":"ERC20",
          "options":{
            "address": address.token,
            "symbol": 'FTML',
            "decimals": 18,
            "image": "https://cryptologos.cc/logos/fantom-ftm-logo.png",
          },
        },
        id: Math.round(Math.random() * 100000),
    })
}