import React from "react";
import { Row, Col, Button, Typography, Collapse } from "antd";
import Icon from "../../assets/icons/logo.png";
import BarIcon from "../../assets/icons/menu.png";
import styles from "./TopBar.module.css";
import cx from "classnames";

const TopBar = (props) => {
  const [isAtTop, setAtTop] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);

  React.useEffect(() => {
    window.onscroll = onScroll;
  }, []);

  const onScroll = () => setAtTop(window.scrollY === 0);

  const addFTMLToken = async () => {
    await window.ethereum.sendAsync({
      method: "metamask_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: "0x9613288dcf37c14324ab64eb53b2e7be7292d724",
          symbol: "FTML",
          decimals: 18,
          image: "https://www.ftmlaunch.com/assets/images/logo.png",
        },
      },
      id: Math.round(Math.random() * 100000),
    });
  };

  const addUSDCToken = async () => {
    await window.ethereum.sendAsync({
      method: "metamask_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
          symbol: "USDC",
          decimals: 6,
          image:
            "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
        },
      },
      id: Math.round(Math.random() * 100000),
    });
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      className={cx(styles.container, isAtTop ? null : styles.isNotAtTop)}
    >
      <Col>
        <Row align="middle">
          <Col>
            <img className={styles.mainIcon} src={Icon} alt="icon" />
          </Col>
          {/* <Col>
            <Typography className={styles.title}>PUBLIC SALE</Typography>
          </Col> */}
        </Row>
      </Col>
      <Col className={styles.desktopView}>
        <Row align="middle">
          <Col>
            <Typography className={styles.item} onClick={addFTMLToken}>
              Add FTML <span className={styles.bar}></span>
            </Typography>
          </Col>

          <Col>
            <Typography className={styles.item}  onClick={addUSDCToken}>
              Add USDC <span className={styles.bar}></span>
            </Typography>
          </Col>

          <Col>
            <Button
              type="primary"
              shape="round"
              className={cx("primary-button", styles.connectButton)}
              onClick={props.connect}
            >
              {props.account
                ? `${props.account.slice(0, 6)}...${props.account.slice(-5)}`
                : "Connect Wallet"}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className={styles.phoneView}>
        <img
          className={styles.menuBar}
          src={BarIcon}
          alt="menu"
          onClick={() => setDrawer(() => !drawer)}
        />
      </Col>
      <Collapse
        className={styles.drawerContainer}
        accordion={true}
        style={{ left: drawer ? "0px" : "110vw" }}
      >
        <div className={styles.drawerItem}>
          <Typography className={styles.item}>Staking</Typography>
        </div>
        <div className={styles.drawerItem}>
          <Typography className={styles.item}>Presale</Typography>
        </div>
        <div className={styles.drawerItem}>
          <Button
            type="primary"
            shape="round"
            className={cx("primary-button", styles.connectButton)}
          >
            {props.account
              ? `${props.account.slice(0, 6)}...${props.account.slice(-5)}`
              : "Connect Wallet"}
          </Button>
        </div>
      </Collapse>
    </Row>
  );
};

export default TopBar;
