import React from "react";
import styles from "./InfoGrid.module.css";

const GridInfo = ({ cards, ...props }) => {
  return (
    <div className={styles.gridContainer}>
          {cards?.map((e, i) => (
            e 
          ))}
    </div>
  );
};

export default GridInfo;
