export const address =  {
    token: '0x9613288dcf37c14324ab64eb53b2e7be7292d724',
    trans: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    staker: '0xcc70f2BF3a3308a4c8CC99dE71d1CA319E7566ab',
    campaign: '0x8Ec9c518D0612A327143d91bf90908b3fcb53791',
};

// export const address =  {
//   token: '0xFC2497695a4b9A0A0eF0BDA853855d2cd726b82b',
//   trans: '0x368a313D38E5b478CfbE315C0F724ddc73Ae0e4d',
//   staker: '0xd2a6032cfbA8F45D84F3264B5Fa99F8A70A354D2',
//   campaign: '0x013589Ed0d82296f39B84e9FA504d202dDEc66ae',
// };