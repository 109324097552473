import React from "react";
import SaleEnded from "./SaleEnded";
import FCFSSale from "./FCFSSale";
import IDOSale from "./IDOSale";
import RegisterCard from "./RegisterCard";
import styles from "./StakingCard.module.css";

import * as Campaign from "../../blockchain/contracts/campaign";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const SaleStep = (props) => {
  const [stage, setStage] = React.useState(5);
  const [chainData, setChainData] = React.useState({
    tier: 0,
    tierName: "",
    userMaxBuy: 0,
    userRemaining: 0,
    fcfsMaxBuy: 0,
    idoTokenBalance: 0,
    fcfsTokenLeft: 0,
    hasRegistered: 0,
    account: "0x",
    progress: 0,
  });

  React.useEffect(() => {
    window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
      if (!accounts.length) return;
      const account = accounts[0];
      Promise.all([
        Campaign.userTier(account),
        Campaign.maxUserAllocationInUSDC(account),
        Campaign.userRemainingMaxBuyInUSDC(account),
        Campaign.fcfsRemainingMaxBuyInUSDC(),
        Campaign.amountBoughtInToken(account),
        Campaign.fcfcTokenLeft(),
        Campaign.hasRegistered(account),
        Campaign.getSaleStage(),
        Campaign.progress()
      ]).then(([tier, userMaxBuy, userRemaining, fcfsMaxBuy, idoTokenBalance, fcfsTokenLeft, hasRegistered, stage, progress]) => {
        const data = {
          tier,
          tierName: Campaign.getTierInfo(tier),
          userMaxBuy: Number(userMaxBuy/1000000).toFixed(2),
          userRemaining: Number(userRemaining/1000000).toFixed(2),
          fcfsMaxBuy: Number(fcfsMaxBuy/1000000).toFixed(2),
          idoTokenBalance: Number(idoTokenBalance).toFixed(2),
          fcfsTokenLeft: Number(fcfsTokenLeft).toFixed(2),
          hasRegistered,
          account: account,
          progress
        }
        setChainData(data);
        setStage(stage);
      });
    });
    console.log("LOADING PROPS >> ", props.loader);
    // eslint-disable-next-line 
  }, [props.loader.isLoading]);
  return (
    <>
      {stage === 1 ? (
        <RegisterCard data={chainData} />
      ) : stage === 2 ? (
        <IDOSale data={chainData} />
      ) : stage === 3 ? (
        <FCFSSale data={chainData} />
      ) : stage === 4 ? (
        <SaleEnded data={chainData} />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div className={styles.loaderContainer}>
            <div className={styles.l1}></div>
            <div className={styles.l2}></div>
            <div className={styles.l3}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  (state) => {
    return { loader: state.Loader };
  },
  { loading }
)(SaleStep);
