import React from "react";
import styles from "./Footer.module.css";
//IMPORTING ICONS
import MediumIcon from "../../assets/icons/Medium Icon.svg";
import TwitterIcon from "../../assets/icons/Twitter Icon.svg";
import TelegramIcon from "../../assets/icons/Telegram Icon.svg";
import HeartIcon from "../../assets/icons/Heart Icon.svg";
import Logo from "../../assets/icons/bottomLogo.png";

import { Typography, Button } from "antd";
import cx from"classnames";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.infoDetailsContainer}>
          <Typography className={styles.infoTitle}>FTMLaunch + You</Typography>
          <Typography className={styles.infoDetails}>
            Join us on our telegram channel to get timely updates regarding all
            the
            <br />
            new features being added
          </Typography>
        </div>
        <Button
          onClick={() => {
            window.open("https://t.me/ftmlaunchchat");
          }}
          type="primary"
          className={cx("primary-button", styles.infoButton)}
        >
          Join on Telegram
        </Button>
      </div>

      <div className={styles.details}>
        <div className={styles.company}>
          <img className={styles.icon} src={Logo} alt="icon" />
          <Typography className={styles.copyrightText}>
            Copyright © FTMLaunch 2021
            <br />
            All rights reserved
          </Typography>
        </div>
        <div className={cx(styles.iconTrey, styles.phoneView)}>
          <img src={MediumIcon} alt="icon" className={styles.socialIcon} />
          <img src={TwitterIcon} alt="icon" className={styles.socialIcon} />
          <img src={TelegramIcon} alt="icon" className={styles.socialIcon} />
        </div>
        <div className={cx(styles.phoneView, styles.contactPhone)}>
          <Typography className={styles.item}>contact@ftmlaunch.com</Typography>
        </div>

        <div className={cx(styles.row, styles.desktopView)}>
          <div>
            <Typography className={styles.title}>Navigation</Typography>
            <div className={styles.row}>
              <div>
                <a href="https://ftmlaunch.com">
                  <Typography className={styles.item}>Home</Typography>
                </a>
                <a href="http://ftmlaunch.com/#scrollDown">
                  <Typography className={styles.item}>About Us</Typography>
                </a>
                <a href="http://ftmlaunch.com/#features">
                  <Typography className={styles.item}>Features</Typography>
                </a>
                <a href="http://ftmlaunch.com/#tier">
                  <Typography className={styles.item}>Tier System</Typography>
                </a>
              </div>
              <div className={styles.subInvisibleDivider}></div>
              <div>
                <a href="http://ftmlaunch.com/#roadmap">
                  <Typography className={styles.item}>Roadmap</Typography>
                </a>
                <a href="http://ftmlaunch.com/#getintouch">
                  <Typography className={styles.item}>Get in Touch</Typography>
                </a>
                <a href="http://ftmlaunch.com/#faqs">
                  <Typography className={styles.item}>FAQs</Typography>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.invisibleDivider}></div>
          <div>
            <Typography className={styles.title}>Contact Us</Typography>
            <Typography className={styles.item}>
              contact@ftmlaunch.com
            </Typography>
            <div className={styles.iconTrey}>
              <a href="https://medium.com/@FTMlaunch">
                <img
                  src={MediumIcon}
                  alt="icon"
                  className={styles.socialIcon}
                />
              </a>
              <a href="https://twitter.com/ftmlaunch">
                <img
                  src={TwitterIcon}
                  alt="icon"
                  className={styles.socialIcon}
                />
              </a>
              <a href="https://t.me/ftmlaunchchat">
                <img
                  src={TelegramIcon}
                  alt="icon"
                  className={styles.socialIcon}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.by}>
        <Typography className={styles.byText}>MADE WITH&nbsp;&nbsp;</Typography>
        <img src={HeartIcon} alt="icon" />
        <Typography className={styles.byText}>&nbsp;&nbsp;by&nbsp;</Typography>
        <Typography className={styles.byText} style={{ fontWeight: "800" }}>
          &nbsp;FTMLaunch
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
