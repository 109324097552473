import React from "react";
import classes from "./ProgressBar.module.css";
import { Typography } from "antd";

export default function ProgressBar(props) {
  return (
    <div className={classes.total}>
      <div className={classes.progress} style={{ width: `${props.progress}%` }}>
        <Typography
          variant="body2"
          className={classes.progressText}
          style={{ display: props.progress > 30 ? "block" : "none" }}
        >
          {String(props.progress) + "%"}{" "}
        </Typography>
      </div>
      <Typography
        variant="body2"
        className={classes.totalText}
        style={{ display: props.progress <= 30 ? "block" : "none", width: `${100-props.progress}%` }}
      >
        {String(props.progress) + "%"}
      </Typography>
    </div>
  );
}
