import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { TopBar, Footer } from "../components";
import { Home } from "../pages";

import { connect } from "react-redux";
import { loading } from "../redux/actions";

import Config from '../blockchain/config';

const RouteHandler = (props) => {

  const [ account, setAccount ] = React.useState(0);
  // eslint-disable-next-line 
  const [ loading, setLoading ] = React.useState(0);

  const [ chain, setChain ] = React.useState(true);
  const [ metamask, setMetamask ] = React.useState(true);


  React.useEffect(() => {
    if(!window.ethereum) setMetamask(false);
    else{
      const config = Config('production');
      window.ethereum.request({ method: "eth_accounts" })
        .then(accounts => setAccount(accounts.length? accounts[0]: 0));
      window.ethereum.request({ method: 'eth_chainId' })
        .then(chainId => Number(chainId) !== Number(config.chainId) ? setChain(false) : setChain(true));
      window.ethereum.on('accountsChanged', accounts => {
        if (!accounts.length)setAccount(0);
        else setAccount(accounts[0]);
        window.location.reload();
      });
      window.ethereum.on('chainChanged',
        chainId => Number(chainId) !== Number(config.chainId) ? setChain(false) : setChain(true)
      );
    }
  }, [chain]);

  // Every time matemask chain value changes, check if content needs to be hiiden
  React.useEffect(() => {
    // put condition for popup here
    props.loading(!(chain && metamask && account!==0 && !loading), !metamask?4:!chain?3:loading?1:4 );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metamask, chain, account, loading]);


  function connect () {
    props.loading(true, 1);
    window.ethereum.request({ method: "eth_requestAccounts" })
      .then(accounts => {
        setAccount(accounts[0]);
        props.loading(false, 1);
      })
      .catch(err => {
        props.loading(false, 1);
      });
  }

  return (
    <>
      <TopBar connect={connect} account={account} />
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};

export default connect(null, { loading })(RouteHandler);

