const loading = (loading, type) => {
  return {
    type: "LOADING",
    payload: {
      loading,
      type,
    },
  };
};

export { loading };
