import web3 from "../web3";
import { CampaignInterface } from '../interfaces';
import { address } from '../address';

import * as Staker from './staker';

const contract = new web3.eth.Contract(CampaignInterface, address.campaign);

const tierMap = {
  0: {
    Tier: 0,
    Name: 'Stake FTML to be eligible'
  },
  1: {
	  Tier: 1,
    Name: "Phobos"
  },
  2: {	
    Tier: 2,
    Name: "Calypso"
  },
  3: {	
    Tier: 3,
    Name: "Hyperion"
  },
  4: {	
    Tier: 4,
    Name: "Oberon"
  },
  5: {	
    Tier: 5,
    Name: "Titan"
  }
}
export const getTierInfo = (tier) => {
  return tierMap[tier].Name;
}
export const hasRegistered = async (address) => {
  return await contract.methods
    .userRegistered(address)
    .call({from: address});
}
export const getSaleStage = async () => {
  const [isInRegistration,
    isInTierSale,
    isInFCFS,
    isInEnd ] = await Promise.all([
      contract.methods.isInRegistration().call(),
      contract.methods.isInTierSale().call(),
      contract.methods.isInFCFS().call(),
      contract.methods.isInEnd().call(),
    ]);
    if(isInRegistration) return 1;
    else if(isInTierSale) return 2;
    else if(isInFCFS) return 3;
    else if(isInEnd) return 4;
    else return 5; // Undetermined stage
}

// Gives maximum buy in USDC, Tokens
// TO BE CONVERTED TO 6 deciamls
export const maxUserAllocationInUSDC = async (address) => {
  try {
    const val = await contract.methods
      .userAllocation(address)
      .call({ from: address });
    return val["maxInvest"];
  }
  catch(err){
    return 0;
  }
}
export const maxUserAllocationInToken = async (address) => {
  try {
    const val = await contract.methods
      .userAllocation(address)
      .call({ from: address });
    return val["maxTokensGet"];
  }
  catch(err){
    return 0;
  }
}

export const amountBoughtInUSDC = async (address) => {
  return await contract.methods
    .participants(address)
    .call({ from: address });
}

export const userRemainingMaxBuyInUSDC = async (address) => {
  const [userMaxAllocUSDC, amountBoughtUSDC] = await Promise.all([
    maxUserAllocationInUSDC(address),
    amountBoughtInUSDC(address)
  ]);
  return userMaxAllocUSDC - amountBoughtUSDC;
}

export const fcfsRemainingMaxBuyInUSDC = async () => {
  return await contract.methods
    .getRemaining()
    .call();
}

export const amountBoughtInToken = async (address) => {
  const amountInUSDC = await amountBoughtInUSDC(address);
  return web3.utils.fromWei(await contract.methods
    .calculateTokenAmount(amountInUSDC)
    .call({from: address}))
}

export const fcfcTokenLeft = async () => {
  const amountInUSDC = await fcfsRemainingMaxBuyInUSDC();
  return web3.utils.fromWei(await contract.methods
    .calculateTokenAmount(amountInUSDC)
    .call());
}

export const calculateTokenAmount = async (amountInUSDC) => {
  return web3.utils.fromWei(await contract.methods
    .calculateTokenAmount(amountInUSDC)
    .call());
}

export const userTier = async (address) => {
  const balance = await Staker.stakedBalance(address);
  let tier = 0;
  if(balance>=720000) tier = 5;
  else if(balance>=360000) tier = 4;
  else if(balance>=180000) tier = 3;
  else if(balance>=60000) tier = 2;
  else if(balance>=15000) tier = 1;
  return tier;
} 

export const progress = async () => {
  let [collected, hardCap] = await Promise.all([
    contract.methods.collectedFTM().call(),
    contract.methods.hardCap().call()
  ]);
  return ((collected*10)/hardCap)*10;
}

//  Send interaction Register and Buy actions
export const register = async (address) => {
  const tierIndex = await userTier(address);
  await contract.methods
    .registerForIDO(tierIndex)
    .send({from: address});
  return true;
}

// Refund money 💰
export const refund = async (address) => {
  await contract.methods
    .refund()
    .send({from: address});
  return true;
}

// In unaltered USDC
export const buyIDO = async (usdc, address) => {
  await contract.methods
    .buyTierTokens(usdc)
    .send({from: address});
  return true;
}

// In unaltered USDC
export const buyFCFS = async (usdc, address) => {
  await contract.methods
    .buyFCFSTokens(usdc)
    .send({from: address});
  return true;
}