import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import "./Loader.less";
import chain from "../../blockchain/chain";


function GlobalLoader(props) {
  function connect() {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        if (accounts.length > 0) window.location.reload();
      });
  }
  async function switchChain() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xFA' }],
      });
      window.location.reload();
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chain],
          });
          window.location.reload();
        } catch (addError) {
          console.error('RPC ERROR: Failed adding the Fantom Chain');
        }
      }
    }
  }

  return (
    <div
      className={
        props.Loader.activeLoaderType1 > 0
          ? "loader-wrapper vsbl"
          : "loader-wrapper nt-vsbl"
      }
    >
      <div id="loader_bar">
        <b></b>
        <i></i>
      </div>
      <div className="center-container">
        {props.Loader.activeLoaderType1Type === 1 && (
          <div className="loader-info">Loading...</div>
        )}
        {props.Loader.activeLoaderType1Type === 2 && (
          <div className="loader-info">Awaiting Transaction ... </div>
        )}
        {props.Loader.activeLoaderType1Type === 3 && (
          <div className="loader-info">
            Please switch to Fantom Chain
            <div style={{ marginTop: "10px" }}>
              <Button
                className="primary-button"
                style={{
                  backgroundColor: "#10378F",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={switchChain}
              >
                Switch
              </Button>
            </div>
          </div>
        )}
        {props.Loader.activeLoaderType1Type === 4 && (
          <div className="loader-info">
            Please connect to Metamask
            <div style={{ marginTop: "10px" }}>
              <Button
                className="primary-button"
                style={{
                  backgroundColor: "#10378F",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={connect}
              >
                Connect
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Loader: state.Loader,
});

export default connect(mapStateToProps, {})(GlobalLoader);
