import React from "react";
import { Typography, Row, Col } from "antd";

import styles from "./InfoCard.module.css";

const InfoCard = ({ title, info }) => {
  return (
    <div className={styles.container}>
      <Typography className={styles.title}>
        {title.split(" ")[0]}
        <br />
        {title.split(" ").length > 1 ? title.split(" ")[1] : ""}
      </Typography>
      <div className={styles.content}>
        {info?.map((e, i) => (
          <Row key={i} container spacing={2} className={styles.infoLine}>
            <Col item xs={12}>
              <Typography className={styles.infoKey}>{e.key}</Typography>
            </Col>
            <Col item xs={12}>
              <Typography className={styles.infoValue}>{e.value}</Typography>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default InfoCard;
