import React from "react";
import classes from "./StakingCard.module.css";
import { Button } from "antd";
import InfoTable from "./infoTable";

import * as Campaign from "../../blockchain/contracts/campaign";


import { connect } from "react-redux";
import { loading } from "../../redux/actions";


const RegisterCard = (props) => {
  // eslint-disable-next-line 
  const [infoData, setInfoData] = React.useState([
    {
      title: "YOUR TIER",
      value: props.data.tier,
    },
    {
      title: "TIER NAME",
      value: props.data.tierName,
    }
  ]);

  async function register() {
    try{
      props.loading(true, 2);
      await Campaign.register(props.data.account);
      return true;
    }
    catch(error){
      console.error(error);
    }
    finally{
      props.loading(false, 2);
    }
  }

  return (
    <>
      <div className={classes.root}>
        <InfoTable data={infoData} title="Registration" />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={register}
          // eslint-disable-next-line 
          disabled={props.data.tier==0 || props.data.hasRegistered}
          // eslint-disable-next-line
          fullWidth
        >
         {props.data.hasRegistered? `Registered in ${props.data.tierName}`: "Register"}
        </Button>
      </div>
      <div style={{ paddingTop: "60px" }}></div>
    </>
  );
}

export default connect(null, { loading })(RegisterCard);