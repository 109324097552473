import React from "react";
import styles from "./Home.module.css";
import BlueFudge1 from "../../assets/images/fudge1.svg";
import BlueFudge2 from "../../assets/images/fudge2.svg";
import { BannerCard } from "../../components";
import GridInfo from "../../components/InfoTable/InfoGrid";
import InfoCard from "../../components/InfoTable/InfoCard";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const Home = (props) => {
  // React.useEffect(() => {
  //   props.loading(false);
  // }, [props]);

  return (
    <div className={styles.container}>
      <img className={styles.blueFudge1} src={BlueFudge1} alt="" />
      <img className={styles.blueFudge2} src={BlueFudge2} alt="" />

      <BannerCard />
      <GridInfo
        cards={[
          [
            <InfoCard
              title={"Project Information"}
              info={[
                { key: "Sale Registration Opens", value: `Feb 24, 17:00 UTC` },
                { key: "Sale Starts", value: "Feb 26, 15:00 UTC" },
                { key: "FCFS Starts", value: "Feb 27 15:00 UTC" },
                {
                  key: "TGE",
                  value: "TBD",
                },
                {
                  key: "Total Raise",
                  value: "100,000 USDC",
                },
                { key: "Vesting", value: "9 months Linear" },
              ]}
            />,
            // <InfoCard
            //   title={"Listing"}
            //   info={[
            //     { key: "Listing Platform", value: "TBA" },
            //     { key: "Listing Date", value: "TBA" },
            //     { key: "Listing Price", value: "1 FTML = 0.008 USD" },
            //   ]}
            // />,
            <InfoCard
              title={"Token Information"}
              info={[
                { key: "Name", value: "DefiYield" },
                { key: "Token Symbol", value: "DEFI" },
                { key: " Token Price", value: "1DEFI= 0.24USDC" },
                { key: "Decimals", value: "18" },
                { key: "Tokens Available", value: "416666 DEFI" },
              ]}
            />,

            // <InfoCard
            //   title={"Presale Schedule"}
            //   info={[
            //     { key: "Presale Start", value: "Dec 7th, 15:00 UTC" },
            //     { key: "Presale End", value: "Until Cap Reached" },
            //     { key: "Type", value: "Whitelist" },
            //   ]}
            // />,
          ],
        ]}
      />
    </div>
  );
};

export default connect(null, { loading })(Home);
