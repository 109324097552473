import React from "react";
import classes from "./StakingCard.module.css";
import { Button } from "antd";
import InfoTable from "./infoTable";

import * as Campaign from "../../blockchain/contracts/campaign";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const SaleEnded = (props) => {
  // eslint-disable-next-line
  const [infoData, setInfoData] = React.useState([
    {
      title: "DeFiYield TOKEN BALANCE",
      value: props.data.idoTokenBalance,
    },
  ]);

  async function refund() {
    try{
      props.loading(true, 2);
      await Campaign.refund(props.data.account);
      return true;
    }
    catch(error){
      console.error(error);
    }
    finally{
      props.loading(false, 2);
    }
  }


  return (
    <>
      <div className={classes.root}>
        <InfoTable data={infoData} title="Sale Retracted" />
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.button}
          // onClick={claim}
          // eslint-disable-next-line
          disabled={true}
          fullWidth
        >
          Ended
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={refund}
          // eslint-disable-next-line
          disabled={false}
          fullWidth
        >
          Refund
        </Button>
      </div>
      <div style={{ paddingTop: "60px" }}></div>
    </>
  );
}

export default connect(null, { loading })(SaleEnded);